var __tnt = window.__tnt || {};

(function(w, d, $, __tnt, undefined) {

    var me = __tnt.ads || (__tnt.ads = {}),
        popupExecuted = false;

    var isVisible = function(el) {
        return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
    };

    me.renderAd = function(adEl) {
        // Make sure we get the right container since it's different if you're in preview or live
        // adDiv makes sure that your <div> is always the right one.
        var adDiv = (adEl.id.indexOf('-debug-') > -1) ? d.querySelector('#' + adEl.id.replace('-debug', '')) : adEl;

        // Get JSON object for BLOX ads.
        var adData = JSON.parse(adEl.dataset.tntAds);

        // List of checks and exceptions used before calling Ad Manager. In general we make sure the adDiv is >= ad width and visible.
        var defaultCheck = adDiv.clientWidth >= adData.width, // Region must be >= ad width
            iphoneCheck = adData.width <= 320 && w.innerWidth == 320, // iPhone5 viewport exception
            ipadCheck = adData.width <= 728 && w.innerWidth == 768, // iPad viewport exception
            visibilityCheck = isVisible(adEl), // Region visibility check
            wallpaperCheck = adData.display == 'wallpaper', // Wallpaper display
            popupCheck = adData.display == 'popup' && popupExecuted == false; // Popup display

        if ((defaultCheck || iphoneCheck || ipadCheck) && visibilityCheck || wallpaperCheck || popupCheck) {
            callAdManager(adData);
        }
    };

    me.render = function() {
        // Get all divs that have the data attribute 'tnt-ads'
        d.querySelectorAll('div[data-tnt-ads]').forEach(function(adEl) {
            me.renderAd(adEl);
        });
    };

    me.resize = function() {
        var resizeTimer,
            windowWidth = $(w).width();

        $(w).on('resize', function() {
            if ($(w).width() != windowWidth) {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function() { me.render(); }, 500);
                windowWidth = $(w).width();
            }
        });
    };

    function callAdManager(ad) {
        // If popup ad, this function should only run once.
        popupExecuted = true;
        // Choose between a 'region' and a 'position'.
        if (ad.type == 'position') {
            TNCMS.AdManager.render({
                region: ad.region,
                slot: ad.slot,
                fold: ad.fold,
                width: ad.width,
                display: ad.display
            });
        }
    }

    // Render and resize on window load
    $(window).load(function() {
        me.render();
        me.resize();
    });

})(window, document, jQuery, __tnt);